import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { ContactModel } from '../../../models/contact.model';
import { ApiService } from '../../../services/api.service';
import { CommonService } from '../../../services/common.service';
import { ContactFormComponent } from '../contact/contact-form/contact-form.component';
import { AgGridDataManagerListComponent } from '../../../lib/data-manager/ag-grid-data-manger-list.component';
import { DatePipe } from '@angular/common';
import { AppModule } from '../../../app.module';
import { AgDateCellRenderer } from '../../../lib/custom-element/ag-list/cell/date.component';
import { AgTextCellRenderer } from '../../../lib/custom-element/ag-list/cell/text.component';
import { agMakeCommandColDef } from '../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../lib/custom-element/ag-list/column-define/selection.define';
import { AgSelect2Filter } from '../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { DialogFormComponent } from '../../dialog/dialog-form/dialog-form.component';
import { FormGroup } from '@angular/forms';
import { RootServices } from '../../../services/root.services';
import { ImportContactsDialogComponent } from '../import-contacts-dialog/import-contacts-dialog.component';
import { ProductModel } from '../../../models/product.model';
import { agMakeImageColDef } from '../../../lib/custom-element/ag-list/column-define/image.define';

@Component({
  selector: 'ngx-contact-all-list',
  templateUrl: './contact-all-list.component.html',
  styleUrls: ['./contact-all-list.component.scss']
})
export class ContactAllListComponent extends AgGridDataManagerListComponent<ContactModel, ContactFormComponent> implements OnInit {

  componentName: string = 'ContactAllListComponent';
  formPath = '/contact/contact-form/form';
  apiPath = '/contact/contacts';
  idKey = ['Code'];
  formDialog = ContactFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = 'calc(100vh - 230px)';


  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<ContactAllListComponent>,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    return super.init().then(async state => {

      this.actionButtonList.unshift({
        name: 'merge',
        status: 'danger',
        label: this.cms.textTransform(this.cms.translate.instant('Common.merge'), 'head-title'),
        icon: 'checkmark-square',
        title: this.cms.textTransform(this.cms.translate.instant('Common.merge'), 'head-title'),
        size: 'medium',
        disabled: () => this.selectedIds.length === 0,
        hidden: () => !this.ref || Object.keys(this.ref).length === 0 ? true : false,
        click: () => {
          console.log('merge contact', this.selectedIds);
          return false;
        },
      });

      if(false) this.actionButtonList.unshift({
        name: 'updateAvatarFromSocialNetwork',
        status: 'danger',
        label: 'Avatar MXH',
        icon: 'flash-outline',
        title: 'Avatar Mạng xã hội',
        size: 'medium',
        disabled: () => this.selectedIds.length === 0,
        // hidden: () => !this.ref || Object.keys(this.ref).length === 0 ? true : false,
        click: async () => {
          if (await new Promise<boolean>(resolve => {
            this.cms.showDialog('Cập nhật avatar từ các nền tảng mạng xã hội', 'Bạn có muốn tuần tự cập nhật avatar của các liên hệ đã chọn không ?', [
              {
                label: 'Trở về',
                status: 'basic',
                action: () => {
                  resolve(false);
                },
              },
              {
                label: 'Bắt đầu cập nhật',
                status: 'primary',
                action: () => {
                  resolve(true);
                },
              },
            ], () => {
              resolve(false);
            })
          })) {

            for (const i in this.selectedItems.filter(f => f.Phone)) {
              const id = this.selectedItems[i].Code;
              let data = { Code: id };
              try {
                while (true) {
                  // const rs = await this.apiService.putPromise<ContactModel[]>(this.apiPath + '/', { id: data.Code, getAvatarBySocialPhone: true }, [data]);
                  // const rs = await this.apiService.getPromise<ContactModel[]>('https://zalo.me/' + this.selectedItems[i].Phone, { token: ''});
                  var xmlHttp = new XMLHttpRequest();
                  xmlHttp.open( "GET", 'https://zalo.me/' + this.selectedItems[i].Phone, false ); // false for synchronous request
                  xmlHttp.send( null );
                  const rs = xmlHttp.responseText;
                  console.log(rs);
                  if (!rs[0]) {
                    this.cms.showError('Không tìm thấy thông tin của liên hệ này trên mạng xã hội');
                    break;
                  }
                  if (rs[0]['Exception'] == 'SECURECHECK') {
                    try {
                      const status = await new Promise((resolve, reject) => {
                        this.cms.showDialog('Cập nhật avatar từ các nền tảng mạng xã hội', 'Yêu cầu kiểm tra bảo mật trước khi tiếp tục! Bạn hãy bấm nút Kiểm tra bảo mật, làm theo hướng dẫn của nền tảng, thoát trang kiểm tra bảo mật và bấm Tiếp tục', [
                          {
                            label: 'Trở về',
                            status: 'basic',
                            action: () => {
                              resolve(false);
                            }
                          },
                          {
                            label: 'Kiểm tra bảo mật',
                            status: 'primary',
                            action: () => {
                              this.cms.openLink(rs[0]['SecureCheckLink'], '_blank');
                              this.cms.showDialog('Cập nhật avatar từ các nền tảng mạng xã hội', 'Bấm nút Tiếp tục sau khi đã làm theo hướng dẫn của mạng xã hội để cập nhật lại avatar của liên hệ ' + this.selectedItems[i].Name, [
                                {
                                  label: 'Trở về',
                                  status: 'basic',
                                  action: () => {
                                    resolve(false);
                                  }
                                },
                                {
                                  label: 'Tiếp tục',
                                  status: 'success',
                                  action: () => {
                                    resolve(true);
                                  }
                                },
                                {
                                  label: 'Dừng',
                                  status: 'warning',
                                  action: () => {
                                    reject(false);
                                  }
                                },
                              ], () => {
                                resolve(false);
                              });
                            }
                          },
                          {
                            label: 'Dừng',
                            status: 'warning',
                            action: () => {
                              reject(false);
                            }
                          },
                        ], () => {
                          resolve(false);
                        });
                      });
                      if (status) {
                        continue;
                      }
                      break;
                    } catch (err) {
                      return false;
                    }
                  }
                  const file = await this.apiService.uploadFileByLink(rs[0]['Avatar']);
                  data['Avatar'] = file;
                  await this.apiService.putPromise<ContactModel[]>(this.apiPath, { id: id }, [data]);
                  break;
                }

              } catch (err) {
                console.error(err);
                this.cms.showError(err);
              }
            }
          }
          return false;
        },
      });

      this.actionButtonList.unshift({
        name: 'importFromFile',
        status: 'primary',
        label: this.cms.textTransform(this.cms.translate.instant('Import'), 'head-title'),
        icon: 'download-outline',
        title: this.cms.textTransform(this.cms.translate.instant('Import'), 'head-title'),
        size: 'medium',
        disabled: () => false,
        hidden: () => false,
        click: () => {
          this.cms.openDialog(ImportContactsDialogComponent, {
            context: {
              // showLoadinng: true,
              inputMode: 'dialog',
              onDialogSave: (newData: ContactModel[]) => {
              },
              onDialogClose: () => {
                this.refresh();
              },
            },
            closeOnEsc: false,
            closeOnBackdropClick: false,
          });
          return false;
        },
      });

      const processingMap = AppModule.processMaps['purchaseOrder'];
      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          ...agMakeImageColDef(this.cms, null, (rowData) => {
            return rowData.Pictures?.map(m => m['LargeImage']);
          }),
          headerName: 'Avatar',
          pinned: 'left',
          field: 'Avatar',
          width: 100,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
        },
        {
          headerName: 'Tên',
          field: 'Name',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          headerName: 'Số di động',
          field: 'Phone',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          headerName: 'Email',
          field: 'Email',
          width: 200,
          filter: 'agTextColumnFilter',
          autoHeight: true,
          // pinned: 'left',
        },
        {
          headerName: 'Nhóm',
          field: 'Groups',
          // pinned: 'left',
          width: 250,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/groups', { includeIdText: true, includeGroups: true, sort_Name: 'asc' }, {
                placeholder: 'Chọn nhóm...', limit: 10, prepareReaultItem: (item) => {
                  // item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Ghi chú',
          field: 'Note',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Người tạo',
          field: 'Creator',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn người tạo...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true, []),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  @Input() prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeGroups'] = true;
    params['eq_IsDeleted'] = false;
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: ContactModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(ContactFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: ContactModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }
}
